import React from "react"

import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"

export const GRIDUNIT = 7;
export const GUTTER = 4;
export const GridWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
`
export const CellWrap = styled.div`
  flex: ${props => `0 0 ${props.width*GRIDUNIT}%`};
  max-width: ${props => `${props.width*GRIDUNIT}%`};
  padding-left: ${props => `${GUTTER/2}%`};
  padding-right: ${props => `${GUTTER/2}%`};
  padding-top: ${props => `${GUTTER/2}%`};
  padding-bottom: ${props => `${GUTTER/2}%`};
  align-self: flex-end;
  @media (max-width: 599px) {
    flex: ${props => `0 0 ${100-GUTTER}%`};
    max-width: ${props => `${100-GUTTER}%`};
  }
  @media (min-width: 600px) {
    flex: ${props => `0 0 ${props.width*GRIDUNIT/1.2}%`};
    max-width: ${props => `${props.width*GRIDUNIT/1.2}%`};
  }
  @media (min-width: 900px) {
    flex: ${props => `0 0 ${props.width*GRIDUNIT/1.2}%`};
    max-width: ${props => `${props.width*GRIDUNIT/1.2}%`};
  }
  @media (min-width: 1200px) {
    flex: ${props => `0 0 ${props.width*GRIDUNIT/1.3}%`};
    max-width: ${props => `${props.width*GRIDUNIT/1.3}%`};
  }
  @media (min-width: 1500px) {
    flex: ${props => `0 0 ${props.width*GRIDUNIT/1.5}%`};
    max-width: ${props => `${props.width*GRIDUNIT/1.5}%`};
  }

  
  a {
    text-decoration: none;
    color: inherit;
  }
`

export const CellTile = styled.div`
  padding: ${props => props.borderless ? '0' : '4vh 4vh'};
  background: #f7f7f7;
  border-radius: 4px;
  display: block;
  video {
    width: 100%;
  }
`

export const CellMedia = ({node}) => {
    if (node.node.frontmatter.featuredVideo) {
      return (
        <video playsInline autoPlay muted controls loop poster={node.node.frontmatter.featuredImage.childImageSharp.fixed.src} src={node.node.frontmatter.featuredVideo.publicURL}>
        </video>
      )
    } else {
      return (
        <Img 
            fluid={{
              ...node.node.frontmatter.featuredImage.childImageSharp.fluid,
              // sizes: node.node.frontmatter.ratio*GRIDUNIT+"vw",
            }}
          />
      )
    }
  }

export const CellCaption = styled.div`
  font-size: .95em;
  line-height: 1.3em;
  margin: 10px 0;
  small {
    display: block;
    font-size: inherit;
    opacity: .4;
  }
`

export const SectionTitle = styled.h3`
  font-size: 1em;
  font-weight: normal;
  margin: 0 2%;
  margin-bottom: .4em;
`;

export const Table = styled.table`
  font-size: 1.2em;
  line-height: 1.3em;
  width: 100%;
  margin-bottom: 2em;
  td {
    padding: .65em 0;
    span {
      color: #999;
    }
    border-bottom: 1px solid #ddd;
  }
  td:first-child {
    color: #999;
    width: 15%;
    padding-left: 2%;
    
  }
  td:nth-child(3) {
    width: 25%;
    
  }
`;

export const TextContent = styled.div`
  margin: 0 2%;
  font-size: 1.2em;
  line-height: 1.3em;
  width: 45%;
  @media (max-width: 599px) {
    width: 70%;
  }

  margin-bottom: 2em;  
`;


export const Container = styled.div`
  margin: 0;
  font-size: 1vw;
  line-height: 1.3em;
  background: white;
  @media (min-width: 1500px) {
    font-size: 0.8vw;
  }
  @media (max-width: 1280px) {
    font-size: 1.1vw;
  }
  @media (max-width: 960px) {
    font-size: 1.3vw;
  }
  @media (max-width: 768px) {
    font-size: 1.8vw;
  }
  @media (max-width: 568px) {
    font-size: 2.8vw;
  }
`
export const InnerContainer = styled.div`
  padding: 14em 0 0 0;
  background: white;
`
export const Title = styled.div`
  h1 {
    font-size: 1em;
    line-height: 1.3em;
    font-weight: normal;
  }
  h2 {
    font-size: 1em;
    line-height: 1.3em;
    font-weight: normal;
    color: #666;
  }
  color: white;
  position: absolute;
  left: 0;
  top: 0;
  margin: 2%;
`

export const Navigation = styled.div`
  mix-blend-mode: difference;
  font-size: 1.8em;
  letter-spacing: -.015em;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  @media (max-width: 568px) {
    font-size: 4vw;
  }
  a {
    color: white;
    text-decoration: none;
    display: inline-block;
    line-height: 1.3em;
    &.active {
      svg circle {
        fill: white;
      }
    }
    .icon {
      display: inline-flex;
      align-self: center;
      margin-right: .3em;
    }
    svg {
      height: 1em;
      width: 1em;
      top: .15em;
      position: relative;
    }
  
  }
`
export const Center = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  margin: 2% 0;
  transform: translateX(-50%);
  a {
    margin-right: 1.3em;
  }
  a:last-child {
    margin-right: 0;
  }
  @media (max-width: 568px) {
    a {
      margin-right: .8em;
    }
  }
`

export const Right = styled.div`
  position: absolute;
  right: 2%;
  top: 0;
  margin: 2% 0;
  text-align: right
  a {
    margin-right: 1.3em;
  }
  a:last-child {
    margin-right: 0;
  }
  @media (max-width: 568px) {
    a {
      margin-right: .8em;
    }
  }
`


export const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  display: inline-block;
  line-height: 1.3em;
  &.active {
    svg circle {
      fill: white;
    }
  }
  .icon {
    display: inline-flex;
    align-self: center;
    margin-right: .3em;
  }
  svg {
    height: 1em;
    width: 1em;
    top: .15em;
    position: relative;
  }
`